"use client";

import Image from 'next/image';

export default function Home() {
  const handleLink = (subdomain: string) => {
    const hostname = window.location.hostname;
    const protocol = window.location.protocol;
    const port = process.env.NODE_ENV === 'development' ? ':3001' : '';
    
    if (hostname === 'localhost') {
      window.location.href = `${protocol}//${subdomain}.${hostname}${port}`;
    } else {
      window.location.href = `${protocol}//${subdomain}.${hostname}`;
    }
  };

  const preloadLink = (subdomain: string) => {
    const hostname = window.location.hostname;
    const protocol = window.location.protocol;
    const port = process.env.NODE_ENV === 'development' ? ':3001' : '';
    const link = hostname === 'localhost' 
      ? `${protocol}//${subdomain}.${hostname}${port}`
      : `${protocol}//${subdomain}.${hostname}`;
    const preloadLink = document.createElement('link');
    preloadLink.rel = 'preload';
    preloadLink.as = 'document';
    preloadLink.href = link;
    document.head.appendChild(preloadLink);
  };

  return (
    <main className="relative min-h-screen overflow-hidden">
      <div className="relative z-10 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16 sm:py-24">
        <div className="text-center space-y-8 animate-fade-in">
          
          
          <p className="max-w-2xl mx-auto text-xl text-silver/80">
            <a href="https://legacy.extremevisiongaming.com" className="text-blue-400 hover:text-blue-300 underline">
              access the legacy app here
            </a>
          </p>

          <div className="grid grid-cols-1 sm:grid-cols-3 gap-6 max-w-4xl mx-auto mt-12 animate-slide-up">
            <button 
              onClick={() => handleLink('www')}
              onMouseEnter={() => preloadLink('www')}
              className="card p-8 animate-float"
            >
              <div className="relative flex flex-col items-center">
                <div className="w-24 h-24 mb-4 relative">
                  <Image
                    src="/xvglogo.webp"
                    alt="WWW Site Logo"
                    fill
                    className="object-contain"
                  />
                </div>
                <h3 className="text-2xl font-bold text-silver mb-2">eXtreme Vision Gaming</h3>
                <p className="text-silver/80">Explore our main website</p>
              </div>
            </button>

            <button 
              onClick={() => handleLink('app')}
              onMouseEnter={() => preloadLink('app')}
              className="card p-8 animate-float"
              style={{ animationDelay: '0.2s' }}
            >
              <div className="relative flex flex-col items-center">
                <div className="w-24 h-24 mb-4 relative">
                  <Image
                    src="/Gold.webp"
                    alt="App Site Logo"
                    fill
                    className="object-contain"
                  />
                </div>
                <h3 className="text-2xl font-bold text-silver mb-2">Gold</h3>
                <p className="text-silver/80">Access our web3 Dapp</p>
              </div>
            </button>

            <button 
              onClick={() => handleLink('slg')}
              onMouseEnter={() => preloadLink('slg')}
              className="card p-8 animate-float"
              style={{ animationDelay: '0.4s' }}
            >
              <div className="relative flex flex-col items-center">
                <div className="w-24 h-24 mb-4 relative">
                  <Image
                    src="/SLG-Discord-Logo.webp"
                    alt="SLG Site Logo"
                    fill
                    className="object-contain"
                  />
                </div>
                <h3 className="text-2xl font-bold text-silver mb-2">Second Life Gaming</h3>
                <p className="text-silver/80">Developer services</p>
              </div>
            </button>
          </div>
        </div>
      </div>
    </main>
  );
}
